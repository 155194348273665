import React, { useState } from "react";
import { Box, ButtonGroup, Button, Container, Card } from "@mui/material";
import NRWDashboard from "../components/NRW2/NRWDashboard";
import NRWManagement from "../components/NRW2/Metering";
import NRWMap from "../components/NRW2/NRWMap";
import DMAMeters from "../components/NRW2/DMAMeters";
import Metering2 from "../components/NRW2/Metering2";
import ProductionMeter from "../components/Operations/ProductionMeter";
import DMAAnalysis from "../components/NRW2/DMAAnalysis";

const selectList = [
  "Dashboard",
  "Interventions",
  "DMA Analysis",
  "DMA Readings",
  "Production Readings",
];

export default function NRW() {
  const [selectedNetwork, setSelectedNetwork] = useState("Dashboard");

  const handleNetworkChange = (network) => {
    setSelectedNetwork(network);
  };

  return (
    <Container sx={{ marginTop: 8 }} disableGutters>
      <Card
        sx={{
          p: 2,
          borderRadius: 5,
          boxShadow: "0px 3px 16px rgba(0, 0, 0, 0.08)",
          minHeight: "90vh",
          display: "grid",
          gridTemplateRows: "auto 1fr",
        }}
      >
        <Box
          variant="contained"
          sx={{
            boxShadow: "none",
            marginBottom: 2,
            display: "flex",
            flexWrap: "wrap",
            gap: 1,
            height: "fit-content",
          }}
          aria-label="network toggle buttons"
        >
          {selectList.map((item, i) => {
            return (
              <Button
                key={i}
                onClick={() => handleNetworkChange(item)}
                variant={selectedNetwork === item ? "contained" : "outlined"}
                sx={{ textTransform: "capitalize" }}
              >
                {item}
              </Button>
            );
          })}
        </Box>

        <Box>{selectedNetwork == "Dashboard" && <NRWDashboard />}</Box>
        <Box>{selectedNetwork == "Interventions" && <Metering2 />}</Box>
        <Box>{selectedNetwork == "DMA Analysis" && <DMAAnalysis />}</Box>
        <Box>{selectedNetwork == "DMA Readings" && <DMAMeters />}</Box>
        <Box>
          {selectedNetwork == "Production Readings" && <ProductionMeter />}
        </Box>
      </Card>
    </Container>
  );
}

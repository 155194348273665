import { useState } from "react";
import {
  Box,
  Tab,
  Tabs,
  Typography,
  Divider,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Card,
} from "@mui/material";

export default function BillingDetails() {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const renderTabContent = () => {
    switch (selectedTab) {
      case 0:
        return <Typography>Basic Information Content</Typography>;
      case 1:
        return <Typography>Meter Readings Content</Typography>;
      case 2:
        return (
          <Box>
            <Typography variant="h6">Invoices</Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Bill No</TableCell>
                  <TableCell>Period</TableCell>
                  <TableCell>Water</TableCell>
                  <TableCell>Sewer</TableCell>
                  <TableCell>Arrears</TableCell>
                  <TableCell>Balance</TableCell>
                  <TableCell>Total</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>001</TableCell>
                  <TableCell>001</TableCell>
                  <TableCell>$120.00</TableCell>
                  <TableCell>$50.00</TableCell>
                  <TableCell>$30.00</TableCell>
                  <TableCell>$100.00</TableCell>
                  <TableCell>$20.00</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>002</TableCell>
                  <TableCell>002</TableCell>
                  <TableCell>$150.00</TableCell>
                  <TableCell>$60.00</TableCell>
                  <TableCell>$40.00</TableCell>
                  <TableCell>$130.00</TableCell>
                  <TableCell>$20.00</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
        );
      case 3:
        return <Typography>Account Status Content</Typography>;
      case 4:
        return <Typography>Map Content</Typography>;
      default:
        return null;
    }
  };

  return (
    <Box marginTop={8}>
      <Card padding={1}>
        <Stack spacing={1}>
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label="Basic Information" />
            <Tab label="Meter Readings" />
            <Tab label="Invoices" />
            <Tab label="Account Status" />
            <Tab label="Map" />
          </Tabs>

          <Divider />

          <Box padding={2}>{renderTabContent()}</Box>
        </Stack>
      </Card>
    </Box>
  );
}

import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { ArrowDown as ArrowDownIcon, ChartLineUp } from "@phosphor-icons/react";
import { ArrowUp as ArrowUpIcon } from "@phosphor-icons/react";
import { Chip } from "@mui/material";

export function TopItem({ title, unit, value }) {
  return (
    <Card
      sx={{
        borderRadius: "8px",
        boxShadow: "0px 3px 16px rgba(0, 0, 0, 0.2)",
        height: "100%",
        width: "100%",
      }}
    >
      <CardContent>
        <Stack>
          <Typography
            sx={{ display: "block" }}
            color="text.secondary"
            variant="overline"
          >
            {title}
          </Typography>
          <Typography sx={{ display: "block" }} color="secondary" variant="h6">
            <Chip label={unit} /> <br />
            {Number(value).toLocaleString(undefined, {
              maximumFractionDigits: 2,
              minimumFractionDigits: unit == "KSh" ? 2 : 0,
            })}
          </Typography>
        </Stack>
      </CardContent>
    </Card>
  );
}

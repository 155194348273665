import {
  ArrowBackIos,
  ArrowForwardIos,
  KeyboardDoubleArrowLeft,
  KeyboardDoubleArrowRight,
} from "@mui/icons-material";
import { Box, Button, IconButton } from "@mui/material";

export default function MyPagination(props) {
  const { currentPage, totalPages, handlePageChange } = props;

  const pagesToShow = totalPages < 5 ? totalPages : 5;
  const startPage = Math.max(1, currentPage - Math.floor(pagesToShow / 2));
  const endPage = Math.min(totalPages, startPage + pagesToShow - 1);

  const pageButtons = [];
  for (let page = startPage; page <= endPage; page++) {
    pageButtons.push(
      <Button
        key={page}
        variant={page === currentPage ? "contained" : "outlined"}
        onClick={() => handlePageChange(page)}
        size="small"
      >
        {page}
      </Button>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexWrap: "wrap",
        gap: 1,
        my: 2,
      }}
    >
      <IconButton
        disabled={currentPage === 1}
        onClick={() => handlePageChange(1)}
      >
        <KeyboardDoubleArrowLeft fontSize="large" />
      </IconButton>
      <IconButton
        disabled={currentPage === 1}
        onClick={() => handlePageChange(currentPage - 1)}
      >
        <ArrowBackIos fontSize="small" />
      </IconButton>
      {pageButtons}
      <IconButton
        disabled={currentPage === totalPages}
        onClick={() => handlePageChange(currentPage + 1)}
      >
        <ArrowForwardIos fontSize="small" />
      </IconButton>
      <IconButton
        disabled={currentPage === totalPages}
        onClick={() => handlePageChange(totalPages)}
        size="small"
      >
        <KeyboardDoubleArrowRight fontSize="large" />
      </IconButton>
    </Box>
  );
}

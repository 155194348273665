import React, { useState, useEffect } from "react";
import { Box, Card, Chip, MenuItem, Select, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { TopItem } from "./TopItem";
import { BarChart } from "@mui/x-charts/BarChart";
import { PieChart } from "@mui/x-charts";

export default function BillingDashboard() {
  const [stats, setStats] = useState(null);
  const [chartsData, setChartsData] = useState(null);
  const currentYear = new Date().getFullYear();
  const [year, setYear] = useState(currentYear);
  // Generate an array of years from the current year back to 10 years ago
  const years = Array.from({ length: 11 }, (_, index) => currentYear - index);

  useEffect(() => {
    // Fetch stats for TopItems
    fetch(`/api/customerbilling/all/stats`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw new Error("");
      })
      .then((data) => {
        setStats(data);
      })
      .catch((e) => console.error(e));
  }, []);

  useEffect(() => {
    fetch(`/api/customerbilling/all/charts/${year}`)
      .then((res) => res.json())
      .then((data) => {
        setChartsData(data);
      })
      .catch((e) => console.error(e));
  }, [year]);

  return (
    <Box marginTop={1} padding={1}>
      <Grid container spacing={3}>
        <Grid item md={2.4}>
          <TopItem title="Total" value={stats ? stats.Total : 0} unit="KSh" />
        </Grid>
        <Grid item md={2.4}>
          <TopItem
            title="Arrears"
            value={stats ? stats.Arrears : 0}
            unit="KSh"
          />
        </Grid>

        <Grid item md={2.4}>
          <TopItem title="Sewer" unit="KSh" value={stats ? stats.Sewer : 0} />
        </Grid>
        <Grid item md={2.4}>
          <TopItem title="Water" unit="KSh" value={stats ? stats.Water : 0} />
        </Grid>
        <Grid item md={2.4}>
          <TopItem
            title="Customers"
            unit="No. "
            value={stats ? stats.Customers : 0}
          />
        </Grid>
      </Grid>
      <Grid marginTop={2} height={300} container spacing={3}>
        <Grid item md={4} xs={12}>
          <Card
            sx={{
              borderRadius: "12px",
              boxShadow: "0px 3px 16px rgba(0, 0, 0, 0.2)",
              height: "100%",
              display: "grid",
              gridTemplateRows: "auto 1fr",
              padding: 2, // Add some padding around the chart
            }}
          >
            <Typography marginLeft={"auto"}>Revenue Collection</Typography>
            <Box
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <PieChart
                series={[
                  {
                    data: stats
                      ? [
                          {
                            label: "Paid",
                            value: stats.Total - stats.Arrears,
                            color: "green",
                          },
                          {
                            label: "Arrears",
                            value: stats.Arrears,
                            color: "orange",
                          },
                        ]
                      : [{ label: "No Data", value: 0 }],
                    highlightScope: { fade: "global", highlight: "item" },
                    faded: {
                      innerRadius: 30,
                      additionalRadius: -30,
                      color: "gray",
                    },
                    innerRadius: 20,
                    outerRadius: "80%",
                  },
                ]}
                slotProps={{
                  legend: {
                    direction: "row",
                    position: { vertical: "bottom", horizontal: "middle" },
                    padding: 0,
                  },
                }}
              />
            </Box>
          </Card>
        </Grid>
        <Grid item md={4} xs={12}>
          <Card
            sx={{
              borderRadius: "12px",
              boxShadow: "0px 3px 16px rgba(0, 0, 0, 0.2)",
              height: "100%",
              display: "grid",
              gridTemplateRows: "auto 1fr",
              padding: 2, // Add some padding around the chart
            }}
          >
            <Typography marginLeft={"auto"}>Water/Sewer</Typography>
            <Box
              sx={{
                width: "100%", // Take full width
                height: "100%", // Take full height
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <PieChart
                series={[
                  {
                    data: stats
                      ? [
                          {
                            label: `Water ${(
                              (stats.Water / (stats.Water + stats.Sewer)) *
                              100
                            ).toFixed(0)}%`,
                            value: stats.Water,
                            color: "blue",
                          },
                          {
                            label: `Sewer ${(
                              (stats.Sewer / (stats.Water + stats.Sewer)) *
                              100
                            ).toFixed(0)}%`,
                            value: stats.Sewer,
                            color: "brown",
                          },
                        ]
                      : [{ label: "No Data", value: 0 }],
                    highlightScope: { fade: "global", highlight: "item" },
                    faded: {
                      innerRadius: 30,
                      additionalRadius: -30,
                      color: "gray",
                    },
                    innerRadius: 20,
                    outerRadius: "80%",
                  },
                ]}
                slotProps={{
                  legend: {
                    direction: "row",
                    position: { vertical: "bottom", horizontal: "middle" },
                    padding: 0,
                  },
                }}
              />
            </Box>
          </Card>
        </Grid>
        <Grid item md={4} xs={12}>
          <Card
            sx={{
              borderRadius: "12px",
              boxShadow: "0px 3px 16px rgba(0, 0, 0, 0.2)",
              height: "100%",
              display: "grid",
              gridTemplateRows: "auto 1fr",
              padding: 2, // Add some padding around the chart
            }}
          >
            <Typography marginLeft={"auto"}>Type</Typography>
            <Box
              sx={{
                width: "100%", // Take full width
                height: "100%", // Take full height
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <PieChart
                series={[
                  {
                    data: chartsData
                      ? chartsData.Estimate
                      : [{ label: "No Data", value: 0 }],
                    highlightScope: { fade: "global", highlight: "item" },
                    faded: {
                      innerRadius: 30,
                      additionalRadius: -30,
                      color: "gray",
                    },
                    innerRadius: 20,
                    outerRadius: "80%",
                  },
                ]}
                slotProps={{
                  legend: {
                    direction: "row",
                    position: { vertical: "bottom", horizontal: "middle" },
                    padding: 0,
                  },
                }}
              />
            </Box>
          </Card>
        </Grid>
      </Grid>
      <Grid marginTop={2} container spacing={3}>
        <Grid my={1} item xs={12}>
          <Card
            sx={{
              display: "grid",
              gridTemplateColumns: "auto 1fr auto",
              boxShadow: "0px 3px 16px rgba(0, 0, 0, 0.2)",
            }}
          >
            <Box sx={{ width: "16px", backgroundColor: "orange" }}></Box>
            <Typography p={1} color="primary" variant="h6">
              Revenue Collection by Month
            </Typography>{" "}
            <Select
              value={year}
              onChange={(e) => setYear(e.target.value)}
              label="Year"
            >
              {years.map((yearOption) => (
                <MenuItem key={yearOption} value={yearOption}>
                  {yearOption}
                </MenuItem>
              ))}
            </Select>
          </Card>
        </Grid>
        <Grid item md={12} xs={12} mb={4}>
          <Card
            sx={{
              borderRadius: "12px",
              boxShadow: "0px 3px 16px rgba(0, 0, 0, 0.2)",
            }}
          >
            <BarChart
              xAxis={[
                {
                  scaleType: "band",
                  data: chartsData
                    ? chartsData?.Month.map((entry) => entry.month)
                    : [""],
                },
              ]}
              yAxis={[
                {
                  valueFormatter: (value) => {
                    if (value >= 1e6) return `${(value / 1e6).toFixed(1)}m`;
                    if (value >= 1e3) return `${(value / 1e3).toFixed(1)}k`;
                    return value.toString();
                  },
                },
              ]}
              series={[
                {
                  data: chartsData
                    ? chartsData?.Month.map((entry) => entry.TotalAmount)
                    : [0],
                  label: "Total Amount",
                  color: "blue",
                },
                {
                  data: chartsData
                    ? chartsData?.Month.map((entry) => entry.TotalArrears)
                    : [0],
                  label: "Total Arrears",
                  color: "orange",
                },
              ]}
              height={400}
            />
          </Card>
        </Grid>
      </Grid>
      <Grid marginTop={2} container spacing={3}>
        <Grid my={1} item xs={12}>
          <Card
            sx={{
              display: "grid",
              gridTemplateColumns: "auto 1fr auto",
              boxShadow: "0px 3px 16px rgba(0, 0, 0, 0.2)",
            }}
          >
            <Box sx={{ width: "16px", backgroundColor: "orange" }}></Box>
            <Typography p={1} color="primary" variant="h6">
              Water/Sewer By Month
            </Typography>{" "}
            <Select
              value={year}
              onChange={(e) => setYear(e.target.value)}
              label="Year"
            >
              {years.map((yearOption) => (
                <MenuItem key={yearOption} value={yearOption}>
                  {yearOption}
                </MenuItem>
              ))}
            </Select>
          </Card>
        </Grid>
        <Grid item md={12} xs={12} mb={4}>
          <Card
            sx={{
              borderRadius: "12px",
              boxShadow: "0px 3px 16px rgba(0, 0, 0, 0.2)",
            }}
          >
            <BarChart
              xAxis={[
                {
                  scaleType: "band",
                  data: chartsData
                    ? chartsData?.Water.map((entry) => entry.month)
                    : [""],
                },
              ]}
              yAxis={[
                {
                  valueFormatter: (value) => {
                    if (value >= 1e6) return `${(value / 1e6).toFixed(1)}m`;
                    if (value >= 1e3) return `${(value / 1e3).toFixed(1)}k`;
                    return value.toString();
                  },
                },
              ]}
              series={[
                {
                  data: chartsData
                    ? chartsData?.Water.map((entry) => entry.Water)
                    : [0],
                  label: "Water",
                  color: "blue",
                },
                {
                  data: chartsData
                    ? chartsData?.Water.map((entry) => entry.Sewer)
                    : [0],
                  label: "Sewer",
                  color: "brown",
                },
              ]}
              height={400}
            />
          </Card>
        </Grid>
      </Grid>
      <Grid marginTop={2} container spacing={3}>
        <Grid my={1} item xs={12}>
          <Card
            sx={{
              display: "grid",
              gridTemplateColumns: "auto 1fr auto",
              boxShadow: "0px 3px 16px rgba(0, 0, 0, 0.2)",
            }}
          >
            <Box sx={{ width: "16px", backgroundColor: "orange" }}></Box>
            <Typography p={1} color="primary" variant="h6">
              Billing By Zone
            </Typography>{" "}
            <Select
              value={year}
              onChange={(e) => setYear(e.target.value)}
              label="Year"
            >
              {years.map((yearOption) => (
                <MenuItem key={yearOption} value={yearOption}>
                  {yearOption}
                </MenuItem>
              ))}
            </Select>
          </Card>
        </Grid>
        <Grid item md={6} xs={12} mb={4}>
          <Card
            sx={{
              borderRadius: "12px",
              boxShadow: "0px 3px 16px rgba(0, 0, 0, 0.2)",
            }}
          >
            <BarChart
              xAxis={[
                {
                  scaleType: "band",
                  data: chartsData
                    ? chartsData?.ZoneWater.map((entry) => entry.name)
                    : [""],
                },
              ]}
              yAxis={[
                {
                  valueFormatter: (value) => {
                    if (value >= 1e6) return `${(value / 1e6).toFixed(1)}m`;
                    if (value >= 1e3) return `${(value / 1e3).toFixed(1)}k`;
                    return value.toString();
                  },
                },
              ]}
              series={[
                {
                  data: chartsData
                    ? chartsData?.ZoneWater.map((entry) => entry.Water)
                    : [0],
                  label: "Water",
                  color: "blue",
                },
              ]}
              height={400}
            />
          </Card>
        </Grid>
        <Grid item md={6} xs={12} mb={4}>
          <Card
            sx={{
              borderRadius: "12px",
              boxShadow: "0px 3px 16px rgba(0, 0, 0, 0.2)",
            }}
          >
            <BarChart
              xAxis={[
                {
                  scaleType: "band",
                  data: chartsData
                    ? chartsData?.ZoneSewer.map((entry) => entry.name)
                    : [""],
                },
              ]}
              yAxis={[
                {
                  valueFormatter: (value) => {
                    if (value >= 1e6) return `${(value / 1e6).toFixed(1)}m`;
                    if (value >= 1e3) return `${(value / 1e3).toFixed(1)}k`;
                    return value.toString();
                  },
                },
              ]}
              series={[
                {
                  data: chartsData
                    ? chartsData?.ZoneSewer.map((entry) => entry.Sewer)
                    : [0],
                  label: "Sewer",
                  color: "brown",
                },
              ]}
              height={400}
            />
          </Card>
        </Grid>
      </Grid>
      <Grid marginTop={2} container spacing={3}>
        <Grid my={1} item xs={12}>
          <Card
            sx={{
              display: "grid",
              gridTemplateColumns: "auto 1fr auto",
              boxShadow: "0px 3px 16px rgba(0, 0, 0, 0.2)",
            }}
          >
            <Box sx={{ width: "16px", backgroundColor: "orange" }}></Box>
            <Typography p={1} color="primary" variant="h6">
              Billing By Sub-Zone
            </Typography>
            <Select
              value={year}
              onChange={(e) => setYear(e.target.value)}
              label="Year"
            >
              {years.map((yearOption) => (
                <MenuItem key={yearOption} value={yearOption}>
                  {yearOption}
                </MenuItem>
              ))}
            </Select>
          </Card>
        </Grid>
        <Grid item md={6} xs={12} mb={4}>
          <Card
            sx={{
              borderRadius: "12px",
              boxShadow: "0px 3px 16px rgba(0, 0, 0, 0.2)",
            }}
          >
            <BarChart
              xAxis={[
                {
                  scaleType: "band",
                  data: chartsData
                    ? chartsData?.SubZoneWater.map((entry) => entry.name)
                    : [""],
                },
              ]}
              yAxis={[
                {
                  valueFormatter: (value) => {
                    if (value >= 1e6) return `${(value / 1e6).toFixed(1)}m`;
                    if (value >= 1e3) return `${(value / 1e3).toFixed(1)}k`;
                    return value.toString();
                  },
                },
              ]}
              series={[
                {
                  data: chartsData
                    ? chartsData?.SubZoneWater.map((entry) => entry.Water)
                    : [0],
                  label: "Water",
                  color: "blue",
                },
              ]}
              height={400}
            />
          </Card>
        </Grid>
        <Grid item md={6} xs={12} mb={4}>
          <Card
            sx={{
              borderRadius: "12px",
              boxShadow: "0px 3px 16px rgba(0, 0, 0, 0.2)",
            }}
          >
            <BarChart
              xAxis={[
                {
                  scaleType: "band",
                  data: chartsData
                    ? chartsData?.SubZoneSewer.map((entry) => entry.name)
                    : [""],
                },
              ]}
              yAxis={[
                {
                  valueFormatter: (value) => {
                    if (value >= 1e6) return `${(value / 1e6).toFixed(1)}m`;
                    if (value >= 1e3) return `${(value / 1e3).toFixed(1)}k`;
                    return value.toString();
                  },
                },
              ]}
              series={[
                {
                  data: chartsData
                    ? chartsData?.SubZoneSewer.map((entry) => entry.Sewer)
                    : [0],
                  label: "Sewer",
                  color: "brown",
                },
              ]}
              height={400}
            />
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}

import { useState, useRef, useEffect } from "react";
import Map from "ol/Map";
import View from "ol/View";
import Overlay from "ol/Overlay";
import TileLayer from "ol/layer/Tile";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import { OSM, XYZ } from "ol/source";
import { Draw } from "ol/interaction";
import GeoJSON from "ol/format/GeoJSON";
import { Circle as CircleStyle, Fill, Stroke, Style } from "ol/style";
import {
  Box,
  Typography,
  Card,
  Divider,
  Paper,
  Select,
  MenuItem,
  TextField,
  Button,
  CircularProgress,
  Container,
  Chip,
  InputLabel,
  FormControl,
} from "@mui/material";

export default function BillingMap() {
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;

  const [map, setMap] = useState(null);
  const mapElement = useRef();
  const popupRef = useRef();
  const [popupContent, setPopupContent] = useState(null);
  const [loadingLayers, setLoadingLayers] = useState([]);
  const [column, setColumn] = useState("Account_No");
  const [operator, setOperator] = useState("=");
  const [searchValue, setSearchValue] = useState("");
  const [legendItems, setLegendItems] = useState([]);
  const [classificationType, setClassificationType] = useState("unique");
  const [year, setYear] = useState(currentYear);
  const [month, setMonth] = useState(currentMonth);
  const [data, setData] = useState([]);

  useEffect(() => {
    const initialMap = new Map({
      target: mapElement.current,
      layers: basemaps.map((b) => b.layer),
      view: new View({
        projection: "EPSG:4326",
        center: [36.8275, -1.1623],
        zoom: 16,
        maxZoom: 32,
      }),
    });

    setMap(initialMap);
    return () => {
      initialMap.setTarget(null);
    };
  }, []);

  useEffect(() => {
    if (map) {
      fetchGeoJSON();

      const popupOverlay = new Overlay({
        element: popupRef.current,
        positioning: "bottom-center",
        stopEvent: false,
      });
      map.addOverlay(popupOverlay);
      map.on("singleclick", function (event) {
        const features = map.getFeaturesAtPixel(event.pixel);
        if (features.length > 0) {
          const properties = features[0].getProperties();
          delete properties.geometry;

          setPopupContent(properties);
          popupOverlay.setPosition(event.coordinate);
        } else {
          setPopupContent(null);
          popupOverlay.setPosition(undefined);
        }
      });
    }
  }, [map, year, month]);

  const fetchGeoJSON = () => {
    setLoadingLayers([`Fetching data...`]);
    const query = new URLSearchParams({
      year,
      month,
    }).toString();

    fetch(`/api/customerbilling/mapdata?${query}`)
      .then((res) => {
        if (!res.ok) throw new Error("Failed to fetch GeoJSON data");
        return res.json();
      })
      .then((data) => {
        if (data) {
          const features = new GeoJSON({
            dataProjection: "EPSG:4326",
            featureProjection: map.getView().getProjection().getCode(),
          }).readFeatures(data);

          setData(features);

          const vectorSource = new VectorSource({
            features: features,
          });

          const vectorLayer = new VectorLayer({
            source: vectorSource,
            style: new Style({
              stroke: new Stroke({
                color: "white",
                width: 2,
              }),
              fill: new Fill({
                color: "#3F51B5",
              }),
              image: new CircleStyle({
                radius: 10,
                fill: new Fill({
                  color: "#3F51B5",
                }),
                stroke: new Stroke({
                  color: "white",
                  width: 1,
                }),
              }),
            }),
          });

          map.getLayers().forEach((layer) => {
            if (layer instanceof VectorLayer) map.removeLayer(layer);
          });

          map.addLayer(vectorLayer);
          setLegendItems(generateLegendItems(features));
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoadingLayers([]);
      });
  };

  const applyFilters = () => {
    let filteredFeatures;

    if (searchValue !== "") {
      setLegendItems([]);
      filteredFeatures = data.filter((feature) => {
        const value = feature.get(column);
        switch (operator) {
          case "=":
            return value === searchValue;
          case "!=":
            return value !== searchValue;
          case ">":
            return parseFloat(value) > parseFloat(searchValue);
          case ">=":
            return parseFloat(value) >= parseFloat(searchValue);
          case "<":
            return parseFloat(value) < parseFloat(searchValue);
          case "<=":
            return parseFloat(value) <= parseFloat(searchValue);
          case "LIKE":
            return value && value.toString().includes(searchValue);
          default:
            return true;
        }
      });
    } else {
      filteredFeatures = data;
    }

    const vectorSource = new VectorSource({
      features: filteredFeatures,
    });

    const styleFunction = getStyleFunction(filteredFeatures);

    const vectorLayer = new VectorLayer({
      source: vectorSource,
      style: styleFunction,
    });

    map.getLayers().forEach((layer) => {
      if (layer instanceof VectorLayer) map.removeLayer(layer);
    });

    map.addLayer(vectorLayer);
    setLegendItems(generateLegendItems(filteredFeatures));
  };

  const resetFilters = () => {
    setColumn("Account_No");
    setOperator("=");
    setSearchValue("");
    setClassificationType("unique");
    const vectorSource = new VectorSource({
      features: data,
    });

    const vectorLayer = new VectorLayer({
      source: vectorSource,
      style: new Style({
        stroke: new Stroke({
          color: "white",
          width: 2,
        }),
        fill: new Fill({
          color: "#3F51B5",
        }),
        image: new CircleStyle({
          radius: 10,
          fill: new Fill({
            color: "#3F51B5",
          }),
          stroke: new Stroke({
            color: "white",
            width: 1,
          }),
        }),
      }),
    });
    map.getLayers().forEach((layer) => {
      if (layer instanceof VectorLayer) map.removeLayer(layer);
    });
    map.addLayer(vectorLayer);
    setLegendItems([]);
  };

  const getStyleFunction = (features) => {
    if (searchValue == "") {
      if (classificationType === "unique") {
        const uniqueValues = [...new Set(features.map((f) => f.get(column)))];
        const colorMap = generateRandomColors(uniqueValues.length);
        return (feature) => {
          const value = feature.get(column);
          const color = colorMap[uniqueValues.indexOf(value)];
          return new Style({
            stroke: new Stroke({
              color: "white",
              width: 2,
            }),
            fill: new Fill({
              color: color,
            }),
            image: new CircleStyle({
              radius: 10,
              fill: new Fill({
                color: color,
              }),
              stroke: new Stroke({
                color: "white",
                width: 1,
              }),
            }),
          });
        };
      } else if (classificationType === "equalInterval") {
        const values = features
          .map((f) => parseFloat(f.get(column)))
          .filter((v) => !isNaN(v));
        const [min, max] = [Math.min(...values), Math.max(...values)];
        const intervals = generateEqualIntervals(min, max, 5);
        const colorMap = generateRandomColors(intervals.length);

        return (feature) => {
          const value = parseFloat(feature.get(column));
          const intervalIndex = intervals.findIndex(
            ([min, max]) => value >= min && value <= max
          );
          const color = colorMap[intervalIndex];
          return new Style({
            stroke: new Stroke({
              color: "white",
              width: 2,
            }),
            fill: new Fill({
              color: color,
            }),
            image: new CircleStyle({
              radius: 10,
              fill: new Fill({
                color: color,
              }),
              stroke: new Stroke({
                color: "white",
                width: 1,
              }),
            }),
          });
        };
      }
    } else {
      return new Style({
        stroke: new Stroke({
          color: "white",
          width: 2,
        }),
        fill: new Fill({
          color: "#3F51B5",
        }),
        image: new CircleStyle({
          radius: 10,
          fill: new Fill({
            color: "#3F51B5",
          }),
          stroke: new Stroke({
            color: "white",
            width: 1,
          }),
        }),
      });
    }
  };

  const generateLegendItems = (features) => {
    if (classificationType === "unique") {
      const uniqueValues = [...new Set(features.map((f) => f.get(column)))];
      const colors = generateRandomColors(uniqueValues.length);
      return uniqueValues.map((value, i) => ({
        label: formatValueWithCommas(value),
        fill: colors[i],
      }));
    } else if (classificationType === "equalInterval") {
      const values = features
        .map((f) => parseFloat(f.get(column)))
        .filter((v) => !isNaN(v));
      const [min, max] = [Math.min(...values), Math.max(...values)];
      const intervals = generateEqualIntervals(min, max, 5);
      const colors = generateRandomColors(intervals.length);
      return intervals.map(([min, max], i) => ({
        label: `${formatValueWithCommas(min)} - ${formatValueWithCommas(max)}`,
        fill: colors[i],
      }));
    }
  };

  const generateRandomColors = (count) => {
    const colors = [];
    for (let i = 0; i < count; i++) {
      colors.push(`#${Math.floor(Math.random() * 16777215).toString(16)}`);
    }
    return colors;
  };

  const generateEqualIntervals = (min, max, n) => {
    const range = (max - min) / n;
    const intervals = [];
    for (let i = 0; i < n; i++) {
      intervals.push([min + i * range, min + (i + 1) * range]);
    }
    return intervals;
  };

  const formatValueWithCommas = (value) => {
    return value.toLocaleString(undefined, {
      maximumFractionDigits: 2,
    });
  };

  return (
    <Container sx={{ marginTop: 1 }} disableGutters>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h5"></Typography>
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ my: 2, gap: 2 }}
      >
        <Select
          value={year}
          onChange={(e) => setYear(e.target.value)}
          displayEmpty
          size="small"
        >
          <MenuItem value={""}>Any</MenuItem>
          <MenuItem value={currentYear}>{currentYear}</MenuItem>
          {Array.from({ length: 10 }, (_, i) => currentYear - 1 - i).map(
            (y) => (
              <MenuItem key={y} value={y}>
                {y}
              </MenuItem>
            )
          )}
        </Select>

        <Select
          value={month}
          onChange={(e) => setMonth(e.target.value)}
          displayEmpty
          size="small"
        >
          <MenuItem value={""}>Any</MenuItem>
          {[
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
          ].map((m, index) => (
            <MenuItem key={index} value={index + 1}>
              {m}
            </MenuItem>
          ))}
        </Select>
        <Box sx={{ flexGrow: 1 }}></Box>
        <FormControl variant="outlined" size="small">
          <InputLabel>Column</InputLabel>
          <Select
            value={column}
            onChange={(e) => setColumn(e.target.value)}
            label="Column"
          >
            <MenuItem value="Account_No">Account No</MenuItem>
            <MenuItem value="OldAcc">Old Account</MenuItem>
            <MenuItem value="Arrears">Arrears</MenuItem>
            <MenuItem value="BAmount">Amount</MenuItem>
            <MenuItem value="Sewer">Sewer</MenuItem>
            <MenuItem value="Water">Water</MenuItem>
          </Select>
        </FormControl>
        <FormControl variant="outlined" size="small">
          <InputLabel>Operator</InputLabel>
          <Select
            value={operator}
            onChange={(e) => setOperator(e.target.value)}
            label="Operator"
          >
            <MenuItem value="=">=</MenuItem>
            <MenuItem value="!=">!=</MenuItem>
            <MenuItem value=">">&gt;</MenuItem>
            <MenuItem value=">=">&gt;=</MenuItem>
            <MenuItem value="<">&lt;</MenuItem>
            <MenuItem value="<=">&lt;=</MenuItem>
            <MenuItem value="LIKE">LIKE</MenuItem>
          </Select>
        </FormControl>
        <TextField
          variant="outlined"
          size="small"
          label="Value"
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />
        <FormControl variant="outlined" size="small">
          <InputLabel>Classification</InputLabel>
          <Select
            value={classificationType}
            onChange={(e) => setClassificationType(e.target.value)}
            label="Classification"
          >
            <MenuItem value="unique">Unique</MenuItem>
            <MenuItem value="equalInterval">Equal Interval</MenuItem>
          </Select>
        </FormControl>
        <Button variant="contained" color="primary" onClick={applyFilters}>
          Apply
        </Button>
        <Button variant="outlined" color="secondary" onClick={resetFilters}>
          Reset
        </Button>
      </Box>
      <Box
        sx={{
          marginTop: 1,
          position: "relative",
          minHeight: "90vh",
          height: "100%",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            overflow: "hidden",
            borderRadius: "8px",
          }}
          className="gis"
        >
          <div
            ref={mapElement}
            style={{ width: "100%", height: "100%" }}
            id="map"
          ></div>

          <Card
            sx={{
              position: "absolute",
              bottom: "1.1rem",
              backgroundColor: "rgba(255,255,255,0.7)",
              right: "10px",
              py: 1,
              px: 2,
              borderRadius: "8px",
            }}
          >
            <Typography variant="h6">Legend</Typography>
            <Divider sx={{ mb: "10px" }} />
            {legendItems.map((item, i) => (
              <LegendItem key={i} item={item} map={map} />
            ))}
          </Card>

          <Paper
            ref={popupRef}
            sx={{
              position: "absolute",
              backgroundColor: "white",
              padding: "10px",
              borderRadius: "8px",
              display: popupContent ? "block" : "none",
              zIndex: 10,
              transform: "translate(-50%, -100%)",
            }}
          >
            <Box sx={{ display: "flex", gap: 2 }}>
              <Typography sx={{ flexGrow: 1 }} variant="subtitle1">
                Billing Details
              </Typography>
              {popupContent && (
                <Chip
                  label={new Date(popupContent.B_Date).toLocaleDateString()}
                />
              )}
            </Box>
            <Divider sx={{ my: "5px" }} />
            {popupContent && <BillingDetailsContent billing={popupContent} />}
          </Paper>

          <Box
            sx={{
              position: "absolute",
              top: "20%",
              right: "10px",
              zIndex: 11,
              display: "flex",
              flexDirection: "column",
              gap: "8px",
            }}
          >
            {loadingLayers.map((label, index) => (
              <LoadingBar key={index} label={label} />
            ))}
          </Box>
        </div>
      </Box>
    </Container>
  );
}

const LegendItem = ({ item }) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center", mb: "3px", gap: 1 }}>
      <Box
        sx={{
          border: `1px solid ${item.fill}`,
          backgroundColor: item.fill,
          height: 16,
          width: 16,
          borderRadius: "50%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      />
      <Typography variant="body2">{item.label}</Typography>
    </Box>
  );
};

const LoadingBar = ({ label }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        backgroundColor: "rgba(255, 255, 255, 0.8)",
        padding: "8px",
        borderRadius: "8px",
        mb: 1,
      }}
    >
      <CircularProgress size={20} sx={{ mr: 1 }} />
      <Typography variant="body2">{label} is loading...</Typography>
    </Box>
  );
};

const BillingDetailsContent = ({ billing }) => {
  return (
    <Box sx={{ width: { xs: "85vw", md: "50vw" } }}>
      <Typography variant="body2">Account No: {billing.Account_No}</Typography>
      <Typography variant="body2">Name: {billing.Name}</Typography>
      <Typography variant="body2">Arrears: {billing.Arrears}</Typography>
      <Typography variant="body2">Amount: {billing.BAmount}</Typography>
      <Typography variant="body2">Sewer: {billing.Sewer}</Typography>
      <Typography variant="body2">Water: {billing.Water}</Typography>
    </Box>
  );
};

const basemaps = [
  {
    label: "OSM",
    layer: new TileLayer({
      source: new OSM(),
      title: "OSM",
      visible: true,
    }),
  },
  {
    label: "Satellite",
    layer: new TileLayer({
      source: new XYZ({
        url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/mapServer/tile/{z}/{y}/{x}",
      }),
      title: "Satellite",
      visible: false,
    }),
  },
  {
    label: "Terrain",
    layer: new TileLayer({
      source: new XYZ({
        url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/mapServer/tile/{z}/{y}/{x}",
      }),
      title: "Terrain",
      visible: false,
    }),
  },
];
